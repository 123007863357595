<template>
    <div class="page page--jobs jobs">

    </div>
</template>

<script>
    export default {
        name: "Jobs",

        mounted () {
            window.scrollTo(0, 0);
        }
    }
</script>